var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay
    && (_vm.displaySearch || (_vm.contentList && _vm.contentList.length > 0) || _vm.empty))?_c('div',{class:['bg-transparent', _vm.gaps]},[_c('div',{staticClass:"entity-list-group",class:[
      _vm.backgroundClass,
      'entity-list-type-'+_vm.type,
      {'border-1 rounded border-neutral-n-4-line border-container': _vm.border && _vm.type !== _vm.EntityType.SPONSOR.toLowerCase()}
    ]},[(_vm.title)?_c('h4',{ref:"entityListTitle",staticClass:"text-left font-weight-bold",class:[
        {'title-sponsor-margin': _vm.type === _vm.EntityType.SPONSOR.toLowerCase()},
        {'mb-3': _vm.type !== _vm.EntityType.SPONSOR.toLowerCase()},
        {'pl-4': _vm.border}
      ]},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]):_vm._e(),(_vm.displaySearch)?_c('div',{class:[
        'mb-3',
        {'px-4': _vm.border}
      ]},[_c('input-search-component',{attrs:{"default-value":_vm.searchValue,"placeholder":("" + (_vm.$t('app.home-page.search-placeholder'))),"size":"md"},on:{"search-query-enter-key":_vm.applySearch}})],1):_vm._e(),(_vm.contentList && _vm.contentList.length > 0)?_c('div',{class:_vm.display === 'grid' ? 'list align-items-start justify-content-start' : 'd-flex flex-column'},_vm._l((_vm.orderedData),function(item,index){return _c('entity-list-item-component',_vm._b({key:(index + "-" + (item.uid)),attrs:{"action-button":_vm.actionButton,"align-left":!_vm.border,"auth-user":_vm.authUser,"no-padding":!_vm.border,"route":_vm.route}},'entity-list-item-component',item,false))}),1):_vm._e(),(!(_vm.contentList && _vm.contentList.length > 0))?_c('p',{staticClass:"paragraph-3 text-neutral-n-6-label m-0 text-left",class:[
        {'px-4': _vm.border}
      ]},[_vm._v(" "+_vm._s(_vm.empty ? _vm.$t(_vm.empty) : _vm.$t('global-search-results-page.no-results-found'))+" ")]):_vm._e(),(_vm.displayViewAll && _vm.viewAllUrl && _vm.viewAllText)?_c('div',{class:[
        'mt-3',
        {'px-4': _vm.border}
      ]},[_c('button-component',{attrs:{"text":("" + (_vm.$t(_vm.viewAllText))),"size":"md","variant":"tertiary"},on:{"on-click":_vm.viewAllClick}})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }